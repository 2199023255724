import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  pageTitle,
  presentationContainer,
  presentationText,
  presentationMainIntro,
  presentationMainText,
  smallScreenImage,
  largeScreenImage,
  companiesSection,
  companiesSectionLabel,
  companiesLogosContainer,
  companyLogo,
  recommendationsSection,
  recommendationsSectionLabel,
  recommendationsContainer,
  evenRecommendation,
  oddRecommendation,
} from "../sass/about.module.scss"

const About = ({ data: allContentfulAboutPage }) => {
  const {
    allContentfulAboutPage: { edges },
  } = allContentfulAboutPage

  const {
    title: { title },
    mainTitle: { mainTitle },
    mainText: { mainText },
    mainImage,
    companies,
    recommendations,
  } = edges[0].node

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | About Guillaume" />
      <div>
        <div className={pageTitle}>{title}</div>
        <div className={presentationContainer}>
          <div className={presentationText}>
            <div className={presentationMainIntro}>{mainTitle}</div>
            <Img
              className={smallScreenImage}
              alt="main image"
              fluid={mainImage.fluid}
            />
            <p className={presentationMainText}>{mainText}</p>
          </div>
          <Img
            className={largeScreenImage}
            alt="main image"
            fluid={mainImage.fluid}
          />
        </div>

        <div className={companiesSection}>
          <div className={companiesSectionLabel}>
            I have worked with these amazing companies:
          </div>
          <div className={companiesLogosContainer}>
            {companies.map(({ title, fluid }) => (
              <Img
                key={title}
                className={companyLogo}
                alt={title}
                fluid={fluid}
              />
            ))}
          </div>
        </div>

        <div className={recommendationsSection}>
          <div className={recommendationsSectionLabel}>
            What was said about me:
          </div>
          <div className={recommendationsContainer}>
            {recommendations.map(({ recommendation }, i) => (
              <div
                key={i}
                className={i % 2 === 0 ? evenRecommendation : oddRecommendation}
              >
                {recommendation.recommendation}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      edges {
        node {
          title {
            title
          }
          mainTitle {
            mainTitle
          }
          mainText {
            mainText
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          companies {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          recommendations {
            recommendation {
              recommendation
            }
          }
        }
      }
    }
  }
`
